import React from 'react';

const HomeSubscribeArea = () => {
    const handleFormSubmit = (e:React.FormEvent) => {
        e.preventDefault();
    }

    return(
        <React.Fragment>
            <section className="subscribe-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="subscribe-image">
                                <img src="assets/img/subscribe-img1.png" data-speed="0.06" data-revert="true"
                                     alt="image"/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="subscribe-content">
                                <h2 className="wow animate__animated animate__fadeInUp" data-wow-delay="00ms"
                                    data-wow-duration="1500ms">
                                    Contact Us
                                </h2>
                                <p className="wow animate__animated animate__fadeInUp" data-wow-delay="100ms"
                                   data-wow-duration="1500ms">
                                    We will be honored if you trust us with your next blockchain project
                                </p>
                                <form className="newsletter-form wow animate__animated animate__fadeInUp"
                                      onSubmit={handleFormSubmit}
                                      data-bs-toggle="validator" data-wow-delay="200ms" data-wow-duration="1500ms">
                                    <input type="text" className="input-newsletter"
                                           placeholder="Enter your email address" name="EMAIL" required
                                           autoComplete="off"/>
                                        <button type="submit" className="default-btn"><i className="flaticon-tick"></i>Subscribe
                                            Now<span></span></button>
                                        <div id="validator-newsletter" className="form-result"></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="vector-shape6"><img src="assets/img/shape/vector-shape6.png" alt="image"/></div>
            </section>
        </React.Fragment>
    )
}

export default HomeSubscribeArea;