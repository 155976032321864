import React from 'react';
import HomeCompanyIntroduction from "../components/Home/HomeCompanyIntroduction";
import HomeFactArea from "../components/Home/HomeFactArea";
import HomeOurServices from "../components/Home/HomeOurServices";
import HomeProcessArea from "../components/Home/HomeProcessArea";
import HomeSubscribeArea from "../components/Home/HomeSubscribeArea";
import HomeOurTeam from "../components/Home/HomeOurTeam";
import HomeProducts from "../components/Home/HomeProducts";

const Home = () => {
    return(
        <React.Fragment>
            <HomeCompanyIntroduction/>

            <HomeFactArea/>

            <HomeOurServices/>

            <HomeProducts/>

            <HomeProcessArea/>

            <HomeOurTeam/>

            <HomeSubscribeArea/>
        </React.Fragment>
    )
}

export default Home;