import React, {useEffect} from 'react';
import './App.css';
import Home from './pages/Home';
import Header from './components/Shared/Header';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import NoPage from "./pages/NoPage";

function App() {

    useEffect(() => {

        const handleScrollEvent = () => {
            const mainHTMLTag = document.querySelector<HTMLElement>('.theme-light')!.scrollTop;
            //console.log('scroll:', mainHTMLTag);

            if (mainHTMLTag > 120) {
                document.querySelector<HTMLElement>(".navbar-area")!.classList.add("is-sticky");
            } else {
                document.querySelector<HTMLElement>(".navbar-area")!.classList.remove("is-sticky");
            }
        }

        window.addEventListener('scroll', handleScrollEvent);

        return () => {
            window.removeEventListener('scroll', handleScrollEvent)
        }
    }, [])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Header/>}>
                    <Route index element={<Home/>}/>
                </Route>
                <Route>
                    <Route path="*" element={<NoPage/>}/>
                </Route>
            </Routes>


        </BrowserRouter>
    );
}

export default App;
