import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import HomeProductItem from "./HomeProductItem";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const dataForSlider = [
    {id: '1', title: 'NFT Marketplace', content: 'A ready-to-launch NFT Marketplace. Users can mint, trade, and bid an NFT. Also, they can explore the NFT market and select anyone they want', link: 'https://nft.relicblock.com', imageURL: './assets/img/new-products/01.png'},
    {id: '2', title: 'IDO Platform', content: 'A ready-to-launch IDO DApp. Project owners can register and put their tokens for the initial offering. Users can buy tokens and invest in the projects', link: 'https://idowhale.com', imageURL: './assets/img/new-products/02.png'},
    {id: '3', title: 'Tokenized Investment Fund', content: 'A ready-to-launch tokenized investment fund. The owner of this platform can accept funds from the investors and trade with that funds.', link: 'https://tif.relicblock.com', imageURL: './assets/img/new-products/03.png'},
]

const HomeProducts = () => {
    const options = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        items: 1,
        navText: [
            "<i class='flaticon-left-1'></i>",
            "<i class='flaticon-right-1'></i>"
        ]
    };

    const navText = [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ];

    return (
        <React.Fragment>
            <section className="case-study-area bg-fffbf5" id="products">
                {/*<div className="case-study-slides owl-carousel owl-theme">*/}
                <OwlCarousel className='case-study-slides owl-carousel owl-theme' loop nav autoplayHoverPause autoplay
                             items={1} navText={navText}>

                    {
                        dataForSlider.map((item) => (
                            <HomeProductItem key={item.id}
                                             id={item.id}
                                             title={item.title}
                                             content={item.content}
                                             link={item.link}
                                             imageURL={item.imageURL}/>
                        ))
                    }

                    {/*<div className="single-case-study-item ptb-100">*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="row align-items-center">*/}

                    {/*            <div className="col-lg-6 col-md-12">*/}
                    {/*                <div className="case-study-content">*/}
                    {/*                        <span className="sub-title"><img src="assets/img/star-icon.png"*/}
                    {/*                                                         alt="image"/> Case study #1</span>*/}
                    {/*                    <h2>Data Science in Pharmaceutical Industries</h2>*/}
                    {/*                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                    {/*                        tempor incididunt ut labore et dolore magna.</p>*/}
                    {/*                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                    {/*                        tempor incididunt ut labore et dolore magna aliqua. Quis ipsum*/}
                    {/*                        suspendisse*/}
                    {/*                        ultrices gravida.</p>*/}
                    {/*                    <a href="single-case-studies-1.html" className="default-btn"><i*/}
                    {/*                        className="flaticon-view"></i>Details More<span></span></a>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="col-lg-6 col-md-12">*/}
                    {/*                <div className="case-study-image" data-tilt>*/}
                    {/*                    <a href="single-case-studies-1.html" className="d-block">*/}
                    {/*                        <img src="assets/img/case-study/img1.jpg" alt="image"/></a>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="single-case-study-item ptb-100">*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="row align-items-center">*/}

                    {/*            <div className="col-lg-6 col-md-12">*/}
                    {/*                <div className="case-study-content">*/}
                    {/*                        <span className="sub-title"><img src="assets/img/star-icon.png"*/}
                    {/*                                                         alt="image"/> Case study #2</span>*/}
                    {/*                    <h2>Mathematics, Advanced Statistics in Python</h2>*/}
                    {/*                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                    {/*                        tempor incididunt ut labore et dolore magna.</p>*/}
                    {/*                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                    {/*                        tempor incididunt ut labore et dolore magna aliqua. Quis ipsum*/}
                    {/*                        suspendisse*/}
                    {/*                        ultrices gravida.</p>*/}
                    {/*                    <a href="single-case-studies-1.html" className="default-btn">*/}
                    {/*                        <i className="flaticon-view"></i>Details More<span></span></a>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="col-lg-6 col-md-12">*/}
                    {/*                <div className="case-study-image" data-tilt>*/}
                    {/*                    <a href="single-case-studies-1.html" className="d-block">*/}
                    {/*                        <img src="assets/img/case-study/img2.jpg" alt="image"/></a>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </OwlCarousel>
                {/*</div>*/}
            </section>
        </React.Fragment>
    )
}

export default HomeProducts;