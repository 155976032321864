import React from 'react';

const HomeCompanyIntroduction = () => {
    return(
        <React.Fragment>
            <div className="it-services-banner" id="home">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="main-banner-content">
                                <h1 className="wow animate__animated animate__fadeInLeft"
                                    data-wow-delay="00ms"
                                    data-wow-duration="1000ms">
                                    Web3 Services Development
                                </h1>
                                <p className="wow animate__animated animate__fadeInLeft" data-wow-delay="100ms"
                                   data-wow-duration="1000ms">
                                    Relic Block company help businesses and individuals worldwide to enter
                                    the Web3 space and start crypto business as soon as possible
                                </p>
                                <div className="btn-box">
                                    <a href="https://api.whatsapp.com/send/?phone=989399757731&text&app_absent=0"
                                       target="_blank"
                                       className="default-btn wow animate__animated animate__fadeInRight"
                                       data-wow-delay="200ms" data-wow-duration="1000ms">
                                        <i className="flaticon-right"></i>
                                        Contact Us
                                        <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="main-banner-image wow animate__animated animate__fadeInUp"
                                 data-wow-delay="00ms" data-wow-duration="1000ms" data-speed="0.06" data-revert="true">
                                <img src="assets/img/banner-img1.png" alt="image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HomeCompanyIntroduction;