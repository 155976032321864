import React from 'react';

interface propType {
    id: string,
    title: string,
    content: string,
    link:string,
    imageURL:string
}

const HomeProductItem = ({id, title, content, link, imageURL}:propType) => {
    return (
        <React.Fragment>
            <div className="single-case-study-item ptb-100">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-6 col-md-12">
                            <div className="case-study-content">
                                            <span className="sub-title">
                                                <img src="assets/img/star-icon.png" alt="image"/>
                                                Product #{id}
                                            </span>
                                <h2>
                                    {title}
                                </h2>
                                <p>
                                    {content}
                                </p>

                                <a href={link} className="default-btn">
                                    <i className="flaticon-view"></i>
                                    Details More
                                    <span></span>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="case-study-image" data-tilt>
                                <a href={link} className="d-block">
                                    <img src={imageURL} alt="image"/>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HomeProductItem;