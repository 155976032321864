import React from 'react';

const OurTeam = () => {
    return(
        <React.Fragment>
            <section className="scientist-area bg-color pt-100 pb-70" id="team">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title"><img src="assets/img/star-icon.png" alt="image"/> Team Members</span>
                        <h2>
                            Our Team
                            <span className="overlay" style={{transformOrigin: 'left 50% 0px', transform: 'matrix(0, 0, 0, 1, 0, 0)'}}></span>
                        </h2>
                        <p>
                            You need a passionated team to do important work. We have this team in the Relic Block
                        </p>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="single-scientist-box wow animate__animated animate__fadeInUp"
                                 data-wow-delay="100ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <img src="assets/img/scientist/img2.png" alt="image"/>
                                </div>
                                <div className="content">
                                    <h3>Mehran Khanjan</h3>
                                    <span>CEO - Blockchain Developer</span>
                                    <ul className="social">
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-facebook'></i></a></li>*/}
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-twitter'></i></a></li>*/}
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-instagram'></i></a></li>*/}
                                        <li>
                                            <a href="https://www.linkedin.com/in/mehran-khanjan" className="d-block" target="_blank">
                                                <i className='bx bxl-linkedin'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="single-scientist-box wow animate__animated animate__fadeInUp"
                                 data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <img src="assets/img/scientist/img1.png" alt="image"/>
                                </div>
                                <div className="content">
                                    <h3>Misbah SP</h3>
                                    <span>CMO</span>
                                    <ul className="social">
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-facebook'></i></a></li>*/}
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-twitter'></i></a></li>*/}
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-instagram'></i></a></li>*/}
                                        <li>
                                            <a href="https://www.linkedin.com/in/misbahsp/" className="d-block" target="_blank">
                                                <i className='bx bxl-linkedin'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="single-scientist-box wow animate__animated animate__fadeInUp"
                                 data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <img src="assets/img/scientist/img4.png" alt="image"/>
                                </div>
                                <div className="content">
                                    <h3>Haris Ejaz</h3>
                                    <span>Blockchain Developer</span>
                                    <ul className="social">
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-facebook'></i></a></li>*/}
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-twitter'></i></a></li>*/}
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-instagram'></i></a></li>*/}
                                        <li>
                                            <a href="https://www.linkedin.com/in/harisejaz22/" className="d-block" target="_blank">
                                                <i className='bx bxl-linkedin'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="single-scientist-box wow animate__animated animate__fadeInUp"
                                 data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <img src="assets/img/scientist/img3.png" alt="image"/>
                                </div>
                                <div className="content">
                                    <h3>Iasmim de Jesus</h3>
                                    <span>Blockchain Developer</span>
                                    <ul className="social">
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-facebook'></i></a></li>*/}
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-twitter'></i></a></li>*/}
                                        {/*<li><a href="#" className="d-block" target="_blank"><i*/}
                                        {/*    className='bx bxl-instagram'></i></a></li>*/}
                                        <li>
                                            <a href="https://www.linkedin.com/in/iasmim-de-jesus-silveira-303924130/"
                                               className="d-block" target="_blank"><i className='bx bxl-linkedin'>
                                            </i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>



                       {/* <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="single-scientist-box wow animate__animated animate__fadeInUp"
                                 data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <img src="assets/img/scientist/img5.png" alt="image"/>
                                </div>
                                <div className="content">
                                    <h3>Alina Sophia</h3>
                                    <span>Data Management</span>
                                    <ul className="social">
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="single-scientist-box wow animate__animated animate__fadeInUp"
                                 data-wow-delay="100ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <img src="assets/img/scientist/img6.png" alt="image"/>
                                </div>
                                <div className="content">
                                    <h3>Liam Oliver</h3>
                                    <span>Data Scientist</span>
                                    <ul className="social">
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="single-scientist-box wow animate__animated animate__fadeInUp"
                                 data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <img src="assets/img/scientist/img7.png" alt="image"/>
                                </div>
                                <div className="content">
                                    <h3>Emma Olivia</h3>
                                    <span>Analytical Solutions</span>
                                    <ul className="social">
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="single-scientist-box wow animate__animated animate__fadeInUp"
                                 data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <img src="assets/img/scientist/img8.png" alt="image"/>
                                </div>
                                <div className="content">
                                    <h3>Elijah William</h3>
                                    <span>Data Scientist</span>
                                    <ul className="social">
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" className="d-block" target="_blank"><i
                                            className='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default OurTeam;