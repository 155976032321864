import React, {useState, useRef} from 'react';
import {Outlet, Link} from 'react-router-dom';
import Footer from "./Footer";
import {CSSTransition} from "react-transition-group";

const Header = () => {
    const [showBlock, setShowBlock] = useState(false);
    const ref = useRef(null);

    const handleHamburgerMenuClick = (e: React.MouseEvent, target:string = '') => {
        e.preventDefault();

        setShowBlock(prevState => !prevState);

        if (target) {
            const specificSection = document.getElementById(target);
            // console.log('specificSection', specificSection);
            specificSection!.scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <React.Fragment>
            <div className="navbar-area">

                <div className="tracer-responsive-nav">
                    <div className="container">
                        <div className="tracer-responsive-menu">

                            <div className="custom-hamburger-menu mean-container" style={{display: 'none'}}>
                                <div className="mean-bar">

                                    <a className="meanmenu-reveal" onClick={e => handleHamburgerMenuClick(e, '')}>
                                        <span><span><span></span></span></span>
                                    </a>

                                    {/*<Transition*/}
                                    {/*    in={showBlock}*/}
                                    {/*    timeout={1000}*/}
                                    {/*    mountOnEnter*/}
                                    {/*    unmountOnExit>*/}
                                    {/*    {(state:TransitionStatus) => (*/}
                                    {/*        <div*/}
                                    {/*            style={{*/}
                                    {/*                backgroundColor: "red",*/}
                                    {/*                width: 100,*/}
                                    {/*                height: 100,*/}
                                    {/*                margin: "auto",*/}
                                    {/*                transition: "opacity 1s ease-out",*/}
                                    {/*                opacity: state === "exiting" ? 0 : 1*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    )}*/}
                                    {/*</Transition>*/}

                                    <CSSTransition
                                        in={showBlock}
                                        timeout={0}
                                        classNames="slide-in-left"
                                        mountOnEnter
                                        unmountOnExit>
                                        <nav className="mean-nav">
                                            <ul className="navbar-nav" >
                                                <li className="nav-item">
                                                    {/*Mobile*/}
                                                    <a href="#home" className="nav-link" onClick={e => handleHamburgerMenuClick(e, 'home')}>
                                                        Home
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#services" className="nav-link" onClick={e => handleHamburgerMenuClick(e, 'services')}>
                                                        Services
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#products" className="nav-link" onClick={e => handleHamburgerMenuClick(e, 'products')}>
                                                        Products
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#team" className="nav-link" onClick={e => handleHamburgerMenuClick(e, 'team')}>
                                                        Team
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#process" className="nav-link" onClick={e => handleHamburgerMenuClick(e, 'process')}>
                                                        Process
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </CSSTransition>

                                </div>
                            </div>


                            <div className="logo black-logo">
                                <Link to="/">
                                    <img src="assets/img/logo.png" alt="logo" style={{width: '50%'}}/>
                                </Link>
                            </div>
                            <div className="logo white-logo">
                                {/*<a href="index.html">*/}
                                {/*    <img src="assets/img/white-logo.png" alt="logo"/>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tracer-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <a className="navbar-brand black-logo" href="index.html">
                                <img src="assets/img/logo.png" alt="logo" style={{width: '50%'}}/>
                            </a>
                            {/*<a className="navbar-brand white-logo" href="index.html">*/}
                            {/*    <img src="assets/img/white-logo.png" alt="logo"/>*/}
                            {/*</a>*/}

                            <div className="collapse navbar-collapse mean-menu">
                                <ul className="navbar-nav">
                                    {/*Desktop*/}
                                    <li className="nav-item">
                                        <a href="#" className="nav-link active">
                                            Home
                                            {/*<i className='bx bx-chevron-down'></i>*/}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#services" className="nav-link">
                                            Services
                                            {/*<i className='bx bx-chevron-down'></i>*/}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#products" className="nav-link">
                                            Products
                                            {/*<i className='bx bx-chevron-down'></i>*/}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#team" className="nav-link">
                                            Team
                                            {/*<i className='bx bx-chevron-down'></i>*/}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#process" className="nav-link">
                                            Process
                                            {/*<i className='bx bx-chevron-down'></i>*/}
                                        </a>
                                    </li>
                                </ul>

                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                        <a className="default-btn"
                                           href="https://api.whatsapp.com/send/?phone=989399757731&text&app_absent=0"
                                           target="_blank">
                                            <i className="flaticon-right"></i>
                                            Contact Us
                                            <span></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                {/*<div className="others-option-for-responsive">*/}
                {/*    <div className="container">*/}
                {/*        <div className="dot-menu">*/}
                {/*            <div className="inner">*/}
                {/*                <div className="circle circle-one"></div>*/}
                {/*                <div className="circle circle-two"></div>*/}
                {/*                <div className="circle circle-three"></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className="container">*/}
                {/*            <div className="option-inner">*/}
                {/*                <div className="others-option">*/}
                {/*                    <div className="option-item">*/}
                {/*                        <a href="contact.html" className="default-btn"><i*/}
                {/*                            className="flaticon-right"></i>Get Started<span></span></a>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <Outlet/>

            <Footer/>

        </React.Fragment>
    )
}

export default Header;