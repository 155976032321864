import React from 'react';

const HomeProcessArea = () => {
    return(
        <React.Fragment>
            <section className="process-area ptb-100 bg-fafafb" id="process">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title">
                            <img src="assets/img/star-icon.png" alt="image"/>How It's Work</span>
                        <h2>Our Data Analytics Process
                            <span className="overlay" style={{transformOrigin: 'left 50% 0px', transform: 'matrix(0, 0, 0, 1, 0, 0)'}}></span>
                        </h2>
                        <p>
                            For creating an efficient DApp, we should follow a path
                        </p>
                    </div>

                    <div className="row align-items-center m-0">
                        <div className="col-lg-6 col-md-12 p-0">
                            <div className="process-image wow animate__animated animate__fadeInUp" data-tilt
                                 data-wow-delay="00ms" data-wow-duration="1500ms">
                                <img src="assets/img/process/img7.png" alt="image"/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 p-0">
                            <div className="process-content">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="single-box-item wow animate__animated animate__fadeInUp"
                                             data-wow-delay="100ms" data-wow-duration="1500ms">
                                            <div className="d-flex align-items-center">
                                                <div className="image">
                                                    <img src="assets/img/process/img-small1.png" alt="image"/>
                                                </div>
                                                <h3>
                                                    Consulting
                                                </h3>
                                                <div className="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="single-box-item wow animate__animated animate__fadeInUp"
                                             data-wow-delay="300ms" data-wow-duration="1500ms">
                                            <div className="d-flex align-items-center">
                                                <div className="image">
                                                    <img src="assets/img/process/img-small1.png" alt="image"/>
                                                </div>
                                                <h3>
                                                    Requirement Analysis
                                                </h3>
                                                <div className="number">2</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="single-box-item wow animate__animated animate__fadeInUp"
                                             data-wow-delay="500ms" data-wow-duration="1500ms">
                                            <div className="d-flex align-items-center">
                                                <div className="image">
                                                    <img src="assets/img/process/img-small1.png" alt="image"/>
                                                </div>
                                                <h3>
                                                    UI/UX design
                                                </h3>
                                                <div className="number">3</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="single-box-item wow animate__animated animate__fadeInUp"
                                             data-wow-delay="700ms" data-wow-duration="1500ms">
                                            <div className="d-flex align-items-center">
                                                <div className="image">
                                                    <img src="assets/img/process/img-small4.png" alt="image"/>
                                                </div>
                                                <h3>
                                                    Prototype
                                                </h3>
                                                <div className="number">4</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="single-box-item wow animate__animated animate__fadeInUp"
                                             data-wow-delay="900ms" data-wow-duration="1500ms">
                                            <div className="d-flex align-items-center">
                                                <div className="image">
                                                    <img src="assets/img/process/img-small5.png" alt="image"/>
                                                </div>
                                                <h3>
                                                    Development
                                                </h3>
                                                <div className="number">5</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="single-box-item wow animate__animated animate__fadeInUp"
                                             data-wow-delay="1000ms" data-wow-duration="1500ms">
                                            <div className="d-flex align-items-center">
                                                <div className="image">
                                                    <img src="assets/img/process/img-small6.png" alt="image"/>
                                                </div>
                                                <h3>
                                                    Quality Assurance
                                                </h3>
                                                <div className="number">6</div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default HomeProcessArea;