import React from 'react';

const HomeFactArea = () => {
    return(
        <React.Fragment>
            <section className="funfacts-area pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                            <div className="single-funfacts-item">
                                <div className="icon">
                                    <img src="assets/img/funfacts/icon1.png" alt="image"/>
                                </div>
                                <h3>20+</h3>
                                <p>Projects Delivered </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                            <div className="single-funfacts-item">
                                <div className="icon">
                                    <img src="assets/img/funfacts/icon2.png" alt="image"/>
                                </div>
                                <h3>3+</h3>
                                <p>Years of Experience</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                            <div className="single-funfacts-item">
                                <div className="icon">
                                    <img src="assets/img/funfacts/icon3.png" alt="image"/>
                                </div>
                                <h3>10+</h3>
                                <p>Blockchain Expert</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                            <div className="single-funfacts-item">
                                <div className="icon">
                                    <img src="assets/img/funfacts/icon5.png" alt="image"/>
                                </div>
                                <h3>5+</h3>
                                <p>Using Technologies</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default HomeFactArea;