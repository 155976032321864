import React from 'react';
import ReactDOM from 'react-dom/client';

import './assets/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/boxicons.min.css';
import './assets/css/flaticon.css';
import './assets/css/nice-select.min.css';
import './assets/css/rangeSlider.min.css';
import './assets/css/fancybox.min.css';
import './assets/css/meanmenu.min.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/magnific-popup.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/dark-style.css';


import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
