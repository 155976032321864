import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

const NoPage = () => {
    const navigate = useNavigate();

    const handleGoBackButtonClick = (e:React.MouseEvent) => {
        e.preventDefault();

        navigate(-1);
    }

    return (
        <React.Fragment>
            <section className="error-area" style={{height: '100vh'}}>
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="error-content">
                                <img src="assets/img/error.png" alt="image"/>
                                <h3>Error 404 : Page Not Found</h3>
                                <p>The page you are looking for might have been removed had its name changed or is
                                    temporarily unavailable.</p>
                                <div className="btn-box">
                                    <a href="" className="default-btn" onClick={handleGoBackButtonClick}>
                                        <i className="flaticon-history"></i>
                                        Go Back
                                        <span></span>
                                    </a>

                                    {/*<Link to={{ pathname: "/nextpath", state: { prevPath: location.pathname } }}>*/}
                                    {/*    Example Link*/}
                                    {/*</Link>;*/}

                                    <Link to="/" className="default-btn">
                                        <i className="flaticon-earth-day"></i>
                                        Homepage
                                        <span></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default NoPage;