import React from 'react';

const HomeOurServices = () => {
    return(
        <React.Fragment>
            <section className="services-area ptb-100" id="services">
                <div className="container">
                    <div className="section-title">
                        <h2>
                            Our Services
                            <span className="overlay" style={{transformOrigin: 'left 50% 0px', transform: 'matrix(0, 0, 0, 1, 0, 0)'}}></span>
                        </h2>
                        <p>
                            We serve you with a wide range of decentralized solutions that add scalability, transparency, and security to your ecosystem
                        </p>
                    </div>

                    <div className="row">

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item wow animate__animated animate__fadeInUp"
                                 data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="icon">
                                    <img src="assets/img/services/icon7.png" alt="image"/>
                                </div>
                                <h3><a>DApp consultation</a></h3>
                                <p>
                                    We help business leaders recognize how blockchain can perfectly fit their business model.
                                </p>
                                <a className="learn-more-btn"><i
                                    className="flaticon-right"></i>Learn More</a>
                                <div className="shape"><img src="assets/img/services/shape4.png" alt="image"/></div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item wow animate__animated animate__fadeInUp"
                                 data-wow-delay="100ms" data-wow-duration="1500ms">
                                <div className="icon">
                                    <img src="assets/img/services/icon8.png" alt="image"/>
                                </div>
                                <h3><a>Smart contracts development</a></h3>
                                <p>
                                    Our team of smart contract developers crafts immutable and accurate smart contracts.
                                </p>
                                <a className="learn-more-btn"><i
                                    className="flaticon-right"></i>Learn More</a>
                                <div className="shape"><img src="assets/img/services/shape4.png" alt="image"/></div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item wow animate__animated animate__fadeInUp"
                                 data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="icon">
                                    <img src="assets/img/services/icon9.png" alt="image"/>
                                </div>
                                <h3><a>DApp and DAO solutions</a></h3>
                                <p>
                                    From creativity, design, and coding to the final launch, we handle your complete DApp development process
                                </p>
                                <a className="learn-more-btn"><i
                                    className="flaticon-right"></i>Learn More</a>
                                <div className="shape"><img src="assets/img/services/shape4.png" alt="image"/></div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item wow animate__animated animate__fadeInUp"
                                 data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="icon">
                                    <img src="assets/img/services/icon10.png" alt="image"/>
                                </div>
                                <h3><a>IDO services</a></h3>
                                <p>
                                    Our comprehensive IDO services include: Preparing your idea for investors and promoting your IDO
                                </p>
                                <a className="learn-more-btn"><i
                                    className="flaticon-right"></i>Learn More</a>
                                <div className="shape"><img src="assets/img/services/shape4.png" alt="image"/></div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item wow animate__animated animate__fadeInUp"
                                 data-wow-delay="100ms" data-wow-duration="1500ms">
                                <div className="icon">
                                    <img src="assets/img/services/icon11.png" alt="image"/>
                                </div>
                                <h3><a>Investment Fund Development</a></h3>
                                <p>
                                    We can develop tokenized investment funds for the customers
                                </p>
                                <a className="learn-more-btn"><i
                                    className="flaticon-right"></i>Learn More</a>
                                <div className="shape"><img src="assets/img/services/shape4.png" alt="image"/></div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item wow animate__animated animate__fadeInUp"
                                 data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="icon">
                                    <img src="assets/img/services/icon12.png" alt="image"/>
                                </div>
                                <h3><a>NFT marketplace</a></h3>
                                <p>
                                    Our blockchain development services also include building decentralized NFT marketplaces
                                </p>
                                <a className="learn-more-btn"><i
                                    className="flaticon-right"></i>Learn More</a>
                                <div className="shape"><img src="assets/img/services/shape4.png" alt="image"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default HomeOurServices;