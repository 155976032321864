import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer-area bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <a className="logo black-logo">
                                    <img style={{width: '65%'}} src="assets/img/logo.png" alt="logo"/>
                                </a>

                                {/*<a className="logo white-logo">*/}
                                {/*    <img src="assets/img/white-logo.png" alt="logo"/>*/}
                                {/*</a>*/}

                                <p>
                                    Web3 Services Development
                                </p>

                                <ul className="social-link">
                                    <li>
                                        <a className="d-block" target="_blank">
                                            <i className='bx bxl-twitter'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://api.whatsapp.com/send/?phone=989399757731&text&app_absent=0" className="d-block" target="_blank">
                                            <i className='bx bxl-whatsapp'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://www.youtube.com/@RelicBlock" className="d-block" target="_blank">
                                            <i className='bx bxl-youtube'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/relic-block-llc" className="d-block" target="_blank">
                                            <i className='bx bxl-linkedin'></i>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Explore</h3>
                                <ul className="footer-links-list">
                                    <li><Link to="/">Home</Link></li>
                                    <li><a>About</a></li>
                                    <li><a>Case Studies</a></li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Resources</h3>
                                <ul className="footer-links-list">
                                    <li><a>Our Team</a></li>
                                    <li><a>Our Services</a></li>
                                    <li><a>Testimonials</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Contact</h3>
                                <ul className="footer-contact-info">

                                    <li>
                                        <i className='bx bxl-whatsapp'></i>
                                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=989399757731&text&app_absent=0">
                                            +98 939 975 7731
                                        </a>
                                    </li>
                                    <li>
                                        <i className='bx bx-envelope'></i>
                                        <a href="mailto: info.relicblock@gamil.com">info.relicblock@gmail.com</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom-area">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <p>
                                    <i className='bx bx-copyright'></i>
                                    <strong>Relic Block</strong> All Right Reserved
                                    {/*<a target="_blank" href=""></a>*/}
                                </p>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <ul>
                                    <li><a>Privacy Policy</a></li>
                                    <li><a>Terms & Conditions</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-map"><img src="assets/img/footer-map.png" alt="image"/></div>
            </footer>
        </React.Fragment>
    )
}

export default Footer;